import React, { JSX, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios, { AxiosRequestConfig } from "axios";
import { EventPurchaseForm } from "../components/_event_purchase_form";
import { EventPurchasePostForClient, PurchaseEvent } from "../types/_index";

import { Loading } from "../components/loading/_index";

type Props = {
	// text: string;
};

export const EventPurchase: React.FC<Props> = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [stripePublicKey, setStripePublicKey] = useState<string>("");
	const [stripePromise, setStripePromise] = useState<any>(null);
	const [event, setEvent] = useState<PurchaseEvent | null>(null);
	const [userName, setUserName] = useState<string>("");
	const [userEmail, setUserEmail] = useState<string>("");

	const [numberOfPurchases, setNumberOfPurchases] = useState<number>(1);

	useEffect(() => {
		const rootEl = document.getElementById("root_event_purchase");
		const dataEventId = rootEl?.getAttribute("data-event-id") as string;
		// const dataEventPrice = rootEl?.getAttribute("data-event-price") as string;
		// const dataEventName = rootEl?.getAttribute("data-event-name") as string;
		const dataUserEmail = rootEl?.getAttribute("data-user-email") as string;
		const dataUserNickname = rootEl?.getAttribute(
			"data-user-nickname",
		) as string;
		console.log("dataEventId", dataEventId);
		// console.log("dataEventPrice", dataEventPrice);
		// console.log("dataEventName", dataEventName);
		console.log("dataUserNickname", dataUserNickname);
		console.log("dataUserEmail", dataUserEmail);

		(async () => {
			setIsLoading(true);
			const response = await axios.get(
				`/wp-json/custom/v1/get-purchase-event-info?event_id=${dataEventId}`,
			);

			console.log("response", response);
			const data = response.data;
			console.log("data", data);
			const purchaseEvent: PurchaseEvent = {
				id: Number(dataEventId),
				price: data.event_price,
				name: data.event_name,
				is_date_exists: data.is_date_exists,
				maximum_number_of_participants:
					data.event_maximum_number_of_participants,
				manage_number_of_applicants: data.event_manage_number_of_applicants,
			};
			setEvent(purchaseEvent);
			setIsLoading(false);
		})();

		setUserName(dataUserNickname);
		setUserEmail(dataUserEmail);

		const metaTagStripePublicKey = document.querySelector(
			'meta[name="stripe-public-key"]',
		) as HTMLMetaElement;
		console.log("metaTagStripePublicKey", metaTagStripePublicKey);
		if (metaTagStripePublicKey) {
			setStripePublicKey(metaTagStripePublicKey?.content);
			const stripePromiseLocal = loadStripe(metaTagStripePublicKey?.content); // Your Publishable Key
			setStripePromise(stripePromiseLocal);
		}
		// loadData();
		setIsLoading(false);
	}, []);

	console.log("stripePublicKey", stripePublicKey);
	console.log("stripePromise", stripePromise);
	if (stripePromise == null) {
		return <></>;
	}

	if (event == null) {
		return <></>;
	}

	console.log("event", event);
	return (
		<>
			<Loading isActive={isLoading} />
			<div
				className="stripeFormWrap"
				data-page-component="_event_purchase_form"
			>
				<Elements stripe={stripePromise}>
					<EventPurchaseForm
						onLoadingStart={() => setIsLoading(true)}
						onLoadingEnd={() => setIsLoading(false)}
						event={event}
						userName={userName}
						userEmail={userEmail}
					/>
				</Elements>
			</div>
		</>
	);
};
