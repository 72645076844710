import axios, { AxiosRequestConfig } from "axios";
import $ from "jquery"; // jQueryをインポート
import React from "react";
import { createRoot } from "react-dom/client";
// import { loadStripe } from "@stripe/stripe-js";

import { EventPurchase } from "./pages/_event_purchase";

document.addEventListener("DOMContentLoaded", () => {
	console.log("DOMContentLoaded");
});

window.addEventListener("load", () => {
	console.log("load");
});

/**
 * root要素毎のコンポーネントをマウントする
 */
const componentMeinFunc = () => {
	document.addEventListener("DOMContentLoaded", async () => {
		console.log("componentMeinFunc");
		const elementIdMap = new Map<string, React.FC<Props>>([
			["root_event_purchase", EventPurchase],
		]);
		console.log("elementIdMap %O", elementIdMap);
		elementIdMap.forEach((component, elementId) => {
			console.log("elementId %O", elementId);
			const el = document.getElementById(elementId);
			console.log("el %O", el);
			if (el) {
				const e = React.createElement;
				createRoot(el).render(e(component));
			}
		});
	});
};

componentMeinFunc();

$(function () {
	const setErrorMessages = (errorMessages: any) => {
		clearErrorMessages();

		for (const [key, value] of Object.entries(errorMessages)) {
			console.log(key, value);
			const target = document.querySelector(
				'[data-error="' + key + '"]',
			) as HTMLElement;

			if (target) {
				target.textContent = value as string;
				target.classList.add("is_show");
			}
		}
	};
	const clearErrorMessages = () => {
		const targets = document.querySelectorAll("[data-error]");
		targets.forEach((target) => {
			target.textContent = "";
			target.classList.remove("is_show");
		});
	};

	const loaderShow = () => {
		document.getElementById("js_loader_bg")?.classList.add("is_show");
	};
	const loaderHide = () => {
		document.getElementById("js_loader_bg")?.classList.remove("is_show");
	};

	const initApiForm = () => {
		$("form[data-ajax-form-target-api]").on("submit", (e) => {
			e.preventDefault();

			const targetForm = e.target as HTMLFormElement;
			const api = targetForm.dataset.ajaxFormTargetApi;
			const formData = new FormData(targetForm);
			// formdataのkeyと値のペアを表示
			for (const [key, value] of formData.entries()) {
				console.log(key, value);
			}
			loaderShow();
			// Ajaxリクエストを送信
			$.ajax({
				type: "POST",
				url: api,
				data: formData,
				processData: false, // FormDataを自動的に処理しないように設定
				contentType: false, // コンテントタイプを自動的に設定しないように設定
				success: (response: any) => {
					console.log("response %O", response);
					const redirect_url = response?.redirect_url;
					if (redirect_url) {
						this.location.href = redirect_url;
					}
				},
				error: (xhr: JQuery.jqXHR, status: string, error: string) => {
					// エラーメッセージを表示
					console.log("form failed: ", xhr?.responseJSON);
					if (xhr?.responseJSON?.data?.error_messages) {
						setErrorMessages(xhr.responseJSON.data.error_messages);
					}
					loaderHide();
				},
			});
		});
	};

	initApiForm();
});

// イベント一覧ページの無限スクロール
document.addEventListener("DOMContentLoaded", () => {
	const archiveEventList = document.getElementById("archive_event_list");
	if (archiveEventList === null) return;

	function getArchiveEventListBottomPosition() {
		if (archiveEventList === null) return null;
		const rect = archiveEventList.getBoundingClientRect();
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const bottom = rect.bottom + scrollTop;
		return bottom;
	}

	let archiveEventListBottomPosition = getArchiveEventListBottomPosition();
	var isRunning = false;
	let isTargetExist = true;

	window.addEventListener("scroll", function () {
		if (isTargetExist === false) return;
		// 呼び出されるまで何もしない
		if (!isRunning) {
			isRunning = true;

			// 描画する前のタイミングで呼び出してもらう
			window.requestAnimationFrame(function () {
				const scrollTop = window.scrollY || window.pageYOffset;
				// ここでなにか処理をする
				// console.log("スクロール位置:", scrollTop);
				// console.log("スクロール位置(下部):", window.innerHeight + scrollTop);
				if (archiveEventListBottomPosition === null) return;
				if (window.innerHeight + scrollTop > archiveEventListBottomPosition) {
					// console.log("archive_event_list の一番下までスクロールした");
					let eventListIndex = 0;
					const targetEventList = document.querySelectorAll(
						"#archive_event_list .is_hide",
					);
					if (targetEventList.length === 0) {
						isTargetExist = false;
						return;
					}
					console.log("targetEventList %O", targetEventList);
					targetEventList.forEach(function (el) {
						if (eventListIndex >= 6) return;
						el.classList.remove("is_hide");
						eventListIndex++;
					});
					archiveEventListBottomPosition = getArchiveEventListBottomPosition();
				}

				isRunning = false;
			});
		}
	});
});
