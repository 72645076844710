import React, { useState } from "react";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { EventPurchasePostForClient, Event } from "../types/_index";
import axios, { AxiosRequestConfig } from "axios";

type Props = {
	onLoadingStart: () => void;
	onLoadingEnd: () => void;
	event: Event;
	userName: string;
	userEmail: string;
};
export const EventPurchaseForm: React.FC<Props> = (props: Props) => {
	console.log("EventPurchaseForm", props);
	const stripe = useStripe();
	const elements = useElements();
	const [errorMessage, setErrorMessage] = useState<string>("");

	const [isErrorCardNumber, setIsErrorCardNumber] = useState(false);
	const [isErrorCardExpiry, setIsErrorCardExpiry] = useState(false);
	const [isErrorCardCvc, setIsErrorCardCvc] = useState(false);
	const [isEnPath, setIsEnPath] = useState(false);
	const [numberOfPurchases, setNumberOfPurchases] = useState<number>(1);
	// コンポーネントの初期化時にのみサービスを呼び出したい
	React.useEffect(() => {
		console.log("useEffect");
		// 現在のURLを取得
		const currentURL = window.location.pathname;
		console.log("currentURL", currentURL);

		// URLをパースして1階層目が/en/であるかどうかを判定
		setIsEnPath(currentURL.startsWith("/en/"));
	}, []);

	const handleSubmit = async () => {
		props?.onLoadingStart();
		console.log("handleSubmit");
		console.log(stripe);
		// エラーは最初に引っかかったのが返ってくるので、エラークラスは1つだけつける
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardNumberElement),

			// billing_details: {
			// 	name: "John Doe",
			// 	email: "john.doe@example.com",
			// },
		});

		const eventId = document
			.getElementById("eventId")
			?.getAttribute("data-event-id");

		if (error) {
			setErrorMessage(error.message);
			console.log(error);
			switch (error.code) {
				case "incomplete_number":
					setIsErrorCardNumber(true);
					setIsErrorCardExpiry(false);
					setIsErrorCardCvc(false);
					break;
				case "incomplete_expiry":
					setIsErrorCardNumber(false);
					setIsErrorCardExpiry(true);
					setIsErrorCardCvc(false);
					break;
				case "incomplete_cvc":
					setIsErrorCardNumber(false);
					setIsErrorCardExpiry(false);
					setIsErrorCardCvc(true);
					break;

				default:
					setIsErrorCardNumber(false);
					setIsErrorCardExpiry(false);
					setIsErrorCardCvc(false);
					break;
			}
			props?.onLoadingEnd();
		} else {
			setIsErrorCardNumber(false);
			setIsErrorCardExpiry(false);
			setIsErrorCardCvc(false);
			console.log("success paymentMethod", paymentMethod);

			(async () => {
				try {
					const response = await axios.post(
						"/wp-json/custom/v1/event-create-payment-intent",
						{
							event_id: props.event.id,
							number_of_purchases: numberOfPurchases,
						},
					);
					const responseData = response?.data;
					console.log(
						"event-create-payment-intent responseData:",
						responseData,
					);
					const clientSecret = responseData.clientSecret;
					console.log("clientSecret:", clientSecret);
					const intent = responseData.intent;
					console.log("before confirmCardPayment intent:", intent);
					stripe
						.confirmCardPayment(clientSecret, {
							payment_method: {
								card: elements.getElement(CardNumberElement), // クレジットカード情報
								billing_details: {
									name: props.userName, // 請求先情報
									email: props.userEmail, // 請求先email
								},
							},
						})
						.then(function (result) {
							if (result.error) {
								// エラーメッセージを表示
								console.error("confirmCardPayment error:", result);
							} else {
								// PaymentIntentが成功した場合の処理
								console.log("success PaymentIntent result", result);
								setErrorMessage("");
								// result.paymentIntentに成功したPaymentIntentが含まれているのでidを指定してイベントと紐づける
								(async () => {
									console.log("relation event_id", props.event.id);
									console.log("relation intent", intent);
									try {
										const response = await axios.post(
											"/wp-json/custom/v1/event-relation-payment-intent",
											{
												event_id: props.event.id,
												intent: intent,
											},
										);
										const responseData = response?.data;
										console.log("responseData:", responseData);

										// 成功したらら購入済み一覧にリダイレクト
										if (isEnPath) {
											location.href = "/en/my-page-application-history/";
										} else {
											location.href = "/my-page-application-history/";
										}
									} catch (error) {
										console.log("error ", error.response);
										// error.response.data.errorMessage
										setErrorMessage("決済に失敗しました");
										props?.onLoadingEnd();
									}
								})();
							}
						});
					// location.href = "/swap/purchase/complete/";
				} catch (error) {
					console.log("error ", error.response);
					// error.response.data.errorMessage
					setErrorMessage("決済に失敗しました");
					props?.onLoadingEnd();
				}
			})();
		}
	};
	errorMessage && console.log("errorMessage", errorMessage);
	console.log("isEnPath", isEnPath);
	const SelectItems = () => {
		const items: JSX.Element[] = [];
		// 日付なしの場合は最大値を決め打ちで100にする、本来は1固定がいいがイレギュラーな物があるかもしれないのでとのこと
		const default_max = 100;
		const remaining = !props.event.is_date_exists
			? default_max
			: props.event.maximum_number_of_participants -
			  props.event.manage_number_of_applicants;
		for (let i = 1; i <= remaining; i++) {
			items.push(
				<option key={i} value={i}>
					{i}
				</option>,
			);
		}
		return items;
	};
	return (
		<div className="stripeForm">
			<div className="stripeFormHeader">
				<div className="eventHeaderRow" key="event">
					<div className="cardFormLabel" key="label">
						{isEnPath ? "Event Name" : "イベント名"}
					</div>
					<div className="" key="value">
						{props.event.name}
					</div>
				</div>
				<div className="eventHeaderRow" key="price">
					<div className="cardFormLabel" key="label">
						{isEnPath ? "Price" : "単価"}
					</div>
					<div className="" key="value">
						{"¥" + props.event.price.toLocaleString()}
					</div>
				</div>
				<div className="eventHeaderRow" key="price">
					<div className="cardFormLabel" key="label">
						{isEnPath ? "Number of purchases" : "数量"}
					</div>
					<div className="" key="value">
						{/* {props.event.is_date_exists ? (
							<>
								<select
									onChange={(e) => {
										setNumberOfPurchases(Number(e.target.value));
									}}
								>
									{SelectItems()}
								</select>
							</>
						) : (
							numberOfPurchases
						)} */}

						<select
							onChange={(e) => {
								setNumberOfPurchases(Number(e.target.value));
							}}
						>
							{SelectItems()}
						</select>
					</div>
				</div>
				<div className="eventHeaderRow" key="price">
					<div className="cardFormLabel" key="label">
						{isEnPath ? "TotalPrice" : "合計価格"}
					</div>
					<div className="" key="value">
						{"¥" + (props.event.price * numberOfPurchases).toLocaleString()}
					</div>
				</div>
			</div>
			<div className="cardFormRow" key="cardNumber">
				<label className="cardFormLabel" htmlFor="cardNumber">
					{isEnPath ? "Card Number" : "カード番号"}
				</label>
				<div
					className={`cardElementWrap cardNumberWrap ${
						isErrorCardNumber ? "isError" : ""
					}`}
				>
					<CardNumberElement id="cardNumber" />
				</div>
			</div>
			<div className="cardFormRow" key="expiry">
				<label className="cardFormLabel" htmlFor="expiry">
					{isEnPath ? "Expiry" : "有効期限"}
				</label>
				<div
					className={`cardElementWrap cardExpiryWrap ${
						isErrorCardExpiry ? "isError" : ""
					}`}
				>
					<CardExpiryElement id="expiry" />
				</div>
			</div>
			<div className="cardFormRow" key="cvc">
				<label className="cardFormLabel" htmlFor="cvc">
					CVC
				</label>
				<div
					className={`cardElementWrap cardCvcWrap ${
						isErrorCardCvc ? "isError" : ""
					}`}
				>
					<CardCvcElement id="cvc" />
				</div>
			</div>
			<div className="purchaseButtonWrap" key="button">
				<button type="submit" className="formButton" onClick={handleSubmit}>
					{isEnPath ? "Purchce" : "決済する"}
				</button>
			</div>

			{errorMessage && (
				<div className="errorMessage" key="error">
					{errorMessage}
				</div>
			)}
		</div>
	);
};
