import React, { JSX, useState, useEffect } from "react";
type Props = {
	isActive: boolean;
};

export const Loading: React.FC<Props> = (props) => {
	const [isActiveClass, setIsShowClass] = useState<string>("");
	const [isShowing, setIsShowing] = useState<boolean>(false);

	useEffect(() => {
		if (props.isActive) {
			setIsShowing(true);
			setIsShowClass("is_show");
		} else {
			setIsShowClass("");
		}
	}, [props.isActive]);

	return (
		<>
			{(props.isActive || isShowing) && (
				<div className={`loader_bg ${isActiveClass} `}>
					<div className="loader">
						<img
							src="/wp/wp-content/themes/yamakoshi_nohaku_theme/assets/images/svg/loading.svg"
							alt=""
						/>
					</div>
				</div>
			)}
		</>
	);
};
